import axios from "axios";

export const api = axios.create({
  // baseURL: "https://openmoreonline.com/api/v1",
  baseURL: "https://admin.openmoreonline.com/api/v1",
});

// https://openmoreonline.com/api/v1/{sub-sectors or sub-types}/{sector_id}/more/{lastId of sublinks}

// https://openmoreonline.com/api/v1/data/latest/null/25
// https://openmoreonline.com/api/v1/data/cat_id/{route id}/20
// https://openmoreonline.com/api/v1/data/sub_sector_id/{route id}/20
// https://openmoreonline.com/api/v1/data/sub_type_id/{route id}/19

// infinite scrolls

export const getMoreSublinks = async (
  linkName,
  linkId,
  lastIndex,
  option = {}
) => {
  const response = await api.get(
    `/${linkName}/${linkId}/more/${lastIndex}`,
    option
  );
  return response.data;
};

export const getMoreItems = async (config, lastIndex, option = {}) => {
  let parameter;
  if (!config.path && !config.id) {
    parameter = "latest";
  } else if (config.path === "category") {
    parameter = "cat_id";
  } else if (config.path === "sub-sectors") {
    parameter = "sub_sector_id";
  } else if (config.path === "sub-types") {
    parameter = "sub_type_id";
  }

  const response = await api.get(
    `/data/${parameter}/${config.id ? config.id : null}/${lastIndex}`,
    option
  );
  return response.data;
};

export const getYoutubeLink = async (id) => {
  const response = await api.get(`/show/${id}`);
  console.log(response);
  return response.data;
};
