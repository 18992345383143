export const container = {
  hidden: { x: "-100%", opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 20,
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
  out: {
    x: "-100%",
    transition: {
      duration: 0.6,
    },
  },
};

export const child = {
  hidden: { x: "-100%", opacity: 0 },
  show: { x: 0, opacity: 1, transition: { duration: 0.1 } },
  out: { x: "-100%", opacity: 0 },
};
