import { configureStore } from "@reduxjs/toolkit";
import itemsReducer from "../features/itemsSlice";
import navlinksReducer from "../features/navLinksSlice";
import lightboxReducer from "../features/lightboxSlice";

const store = configureStore({
  reducer: {
    navlinks: navlinksReducer,
    items: itemsReducer,
    lightbox: lightboxReducer,
  },
});
export default store;
