import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectIndustry,
  selectIndustrySublinks,
} from "../features/navLinksSlice";
import Dropdown from "./Dropdown";
import { HiChevronDown } from "react-icons/hi";
import { useState } from "react";
import useSublinks from "../hooks/useSublinks";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import NavLinkWrapper from "../UI/NavLinkWrapper";
import Loader from "./Loader";

const DropdownIndustry = ({ variant, handleMenu }) => {
  const industry = useSelector(selectIndustry);
  const sublinks = useSelector(selectIndustrySublinks);
  const [moreSublinks, setMoreSublinks] = useState([]);
  const [lastIndex, setlastIndex] = useState(industry.sublinks.at(-1).id);

  const { results, isLoading, isError, error, hasNextSublinks } = useSublinks(
    "sub-types",
    industry.id,
    lastIndex
  );

  const { pathname } = useLocation();
  const matchIndustry = pathname.startsWith("/sub-types");

  useEffect(() => {
    setMoreSublinks([...sublinks, ...results]);
  }, [results, sublinks]);

  const intObserver = useRef();
  const lastLinkRef = useCallback(
    (dropdown) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((dropdown) => {
        if (dropdown[0].isIntersecting && hasNextSublinks) {
          setlastIndex(results.at(-1)?.id);
        }
      });

      if (dropdown) intObserver.current.observe(dropdown);
    },
    [isLoading, results, hasNextSublinks]
  );

  if (isError) return <p>Error:{error.message}</p>;

  const content = moreSublinks.map((cur, i) => {
    if (moreSublinks.length === i + 1) {
      return (
        <Dropdown
          key={cur.id}
          sublink={cur}
          ref={lastLinkRef}
          type="sub-types"
          handleMenu={handleMenu}
        />
      );
    }
    return (
      <Dropdown
        key={cur.id}
        sublink={cur}
        type="sub-types"
        handleMenu={handleMenu}
      />
    );
  });

  return (
    <NavLinkWrapper variant={variant}>
      <button
        className={`${variant ? "nav__link-mobile" : "nav__link-main"} ${
          Boolean(matchIndustry) ? "text-white" : ""
        }`}
      >
        {industry?.name}
        <HiChevronDown
          className={`${
            variant ? "group-hover:-rotate-90 nav__link-icon inline" : ""
          } nav__link-icon`}
        />
      </button>

      <div
        className={`${
          variant ? "dropdown__mobile" : "dropdown industry"
        } group-hover:block hover:block`}
      >
        <ul>{content}</ul>
        {isLoading && (
          <div className="py-2">
            <Loader variant={true} />
          </div>
        )}
      </div>
    </NavLinkWrapper>
  );
};

export default DropdownIndustry;
