import React from "react";
import youTubeImg from "../assets/pngfind.com-youtube-logo-png-1235246.png";

const YouTubeOverlay = ({ toggle }) => {
  return (
    <img
      src={youTubeImg}
      alt="youtube"
      className="text-6xl absolute top-1/2 left-1/2 -translate-y-1/2 opacity-80 -translate-x-1/2 cursor-pointer h-12"
      onClick={toggle}
    />
  );
};

export default YouTubeOverlay;
