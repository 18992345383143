import React from "react";

const Map = () => {
  return (
    <div className="border-2 border-gray w-full pb-[70%] relative">
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1jco8sPPu0TZO2yEtd2BCgfo7mm5n7KxH&ehbc=2E312F"
        width="100%"
        allowFullScreen=""
        loading="lazy"
        className="absolute w-full h-full top-0 left-0"
      ></iframe>
      {/* <iframe
        src="https://www.google.com/maps/d/embed?mid=13n_7LoVgmpCgcsJcGvpKmDNCd1aMJSY&ehbc=2E312F"
        width="640"
        height="480"
        loading="lazy"
      ></iframe> */}
    </div>
  );
};

export default Map;
