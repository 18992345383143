import React from "react";
import FsLightbox from "fslightbox-react";
import { useSelector } from "react-redux";
import {
  selectClickedId,
  selectIsOpenedLightbox,
  selectLightboxItems,
  selectLightboxType,
} from "../features/lightboxSlice";

const Lightbox = () => {
  const lightboxType = useSelector(selectLightboxType);
  const clickedId = useSelector(selectClickedId);
  const lightboxItems = useSelector(selectLightboxItems);
  const isOpened = useSelector(selectIsOpenedLightbox);
  const items = lightboxItems.map((cur) => cur.value);
  const id = Object.keys(lightboxItems);

  return (
    <FsLightbox
      sourceIndex={clickedId}
      toggler={isOpened}
      sources={items}
      key={id}
      type={lightboxType}
    />
  );
};

export default Lightbox;
