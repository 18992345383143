import React, { useEffect } from "react";
import Logo from "../UI/Logo";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import controlBody from "../utils/controlBody";
import { useSelector } from "react-redux";
import {
  selectCategory,
  selectError,
  selectStatus,
} from "../features/navLinksSlice";
import DropdownBusiness from "./DropdownBusiness";
import DropdownIndustry from "./DropdownIndustry";

import { container, child } from "../UI/motion";

const defaultLinks = [
  { id: 1, name: "about us", route: "/about" },
  { id: 2, name: "contact us", route: "/contact" },
];

const MobileMenu = ({ isMobileMenuOpen, handleMenu }) => {
  const categories = useSelector(selectCategory);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  useEffect(() => {
    controlBody(isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  let content;
  if (status === "pending") {
    content = <p className="xl:hidden block">loading...</p>;
  } else if (status === "success") {
    content = (
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        exit="out"
        className="mobileMenu"
      >
        <div className="m-6">
          <Logo />
        </div>
        <ul className="px-6 md:px-12 space-y-5 mt-16 flex flex-col  bg-black items-start">
          <li className="nav__link-mobileContainer" onClick={handleMenu}>
            <NavLink to="/" variant={child} end className="nav__link-mobile">
              Home
            </NavLink>
          </li>
          {categories.map((link) => (
            <motion.li
              key={link.id}
              className="nav__link-mobileContainer"
              variants={child}
              onClick={handleMenu}
            >
              <NavLink
                to={`category/${link.id}`}
                className="nav__link-mobile"
                end
              >
                {link.name}
              </NavLink>
            </motion.li>
          ))}

          <DropdownBusiness variant="mobile" handleMenu={handleMenu} />
          <DropdownIndustry variant="mobile" handleMenu={handleMenu} />

          {defaultLinks.map((link) => (
            <motion.li
              className="nav__link-mobileContainer z-10"
              variants={child}
              key={link.id}
              onClick={handleMenu}
            >
              <NavLink to={link.route} className="nav__link-mobile">
                {link.name}
              </NavLink>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    );
  } else if (status === "failed") {
    content = <p>{error}</p>;
  }

  return <AnimatePresence>{isMobileMenuOpen && content}</AnimatePresence>;
};

export default MobileMenu;
