import React from "react";

const PageNotFound = () => {
  console.log("hello");
  return (
    <div
      className="min-h-screen bg-lightest flex items-center justify-center 
    "
    >
      <div className="flex items-center">
        <p className="text-dark-gray text-xl font-light pr-4 border-r border-slate-300 tracking-normal">
          404
        </p>
        <p className="text-dark-gray text-xl font-light pl-4 tracking-normal uppercase">
          Not Found
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
