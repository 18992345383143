import React from "react";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import ModalOverlay from "../UI/ModalOverlay";
import controlBody from "../utils/controlBody";

const container = {
  hidden: { x: "100%", opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 20,
      delayChildren: 0.4,
      staggerChildren: 0.2,
    },
  },
  out: {
    x: "100%",
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
    },
  },
};

const child = {
  hidden: { x: -50, opacity: 0 },
  show: { x: 0, opacity: 1 },
  out: { x: -50, opacity: 0 },
};

const Info = ({ isInfoOpen, handleInfo }) => {
  controlBody(isInfoOpen);
  return (
    <AnimatePresence>
      {isInfoOpen && (
        <ModalOverlay onClick={handleInfo}>
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            exit="out"
            className="info"
          >
            <div
              className="px-6 py-7 bg-black uppercase text-gray flex items-center justify-between"
              onClick={(e) => e.stopPropagation()}
            >
              <p className="font-bold leading-none text-gray">
                Open more online
              </p>
              <MdClose
                className="text-xl cursor-pointer duration-100 hover:text-white"
                onClick={handleInfo}
              />
            </div>
            <ul className="py-6 font-thin">
              <motion.li variants={child}>
                <NavLink className="info__links" to="/about">
                  About us
                </NavLink>
              </motion.li>
              <motion.li variants={child}>
                <NavLink className="info__links" to="/contact">
                  contact
                </NavLink>
              </motion.li>
            </ul>
          </motion.div>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default Info;
