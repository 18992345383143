import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const Dropdown = React.forwardRef(({ sublink, type, handleMenu }, ref) => {
  const link = (
    <NavLink
      to={`/${type}/${sublink.id}`}
      className="sublinks px-3 py-2 inline-block w-full hover:bg-gray-light hover:text-dark-brown sublinksor-pointer duration-100 text-gray uppercase tracking-normal"
    >
      {sublink.name}
    </NavLink>
  );

  const content = ref ? (
    <motion.li
      ref={ref}
      onClick={handleMenu}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      layout
    >
      {link}
    </motion.li>
  ) : (
    <motion.li
      onClick={handleMenu}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      layout
    >
      {link}
    </motion.li>
  );
  return content;
});

export default Dropdown;
