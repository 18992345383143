import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Gallery from "../components/Gallery";
import Loader from "../components/Loader";
import {
  fetchItems,
  selectItems,
  selectItemsError,
  selectItemsStatus,
} from "../features/itemsSlice";
import { selectStatus } from "../features/navLinksSlice";

const ENDPOINT = "latest";
const HomePage = () => {
  // initial loading items
  const { id } = useParams();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const status = useSelector(selectItemsStatus);
  const error = useSelector(selectItemsError);
  const navStatus = useSelector(selectStatus);

  useEffect(() => {
    dispatch(fetchItems({ endpoint: ENDPOINT, id: null }));
  }, [id, ENDPOINT]);

  if (status === "pending") {
    return <Loader styles="my-16" />;
  } else if (
    status === "success" &&
    items.length !== 0 &&
    navStatus === "success"
  ) {
    return <Gallery />;
  } else if (items.length === 0 && status === "success") {
    return <p className="my-12">No Posts Available Yet!</p>;
  } else if (status === "failed" && error) {
    return <p className="my-12">{error}</p>;
  }
};

export default HomePage;
