import React from "react";

const Loader = ({ styles, variant }) => {
  return (
    <div
      className={`spinner mx-auto ${styles} ${
        variant ? "w-6 h-6" : "w-10 h-10"
      }`}
      aria-label="loader"
    ></div>
  );
};

export default Loader;
