import React from "react";
import Lightbox from "./components/Lightbox";
import Pages from "./pages/Pages";

const App = () => {
  return (
    <>
      <Pages />
      <Lightbox />
    </>
  );
};

export default App;
