import React from "react";
import { AiTwotonePhone } from "react-icons/ai";
import { HiMail } from "react-icons/hi";
import { ImHome3 } from "react-icons/im";
import Map from "../components/Map";

const ContactusPage = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:space-x-6 space-x-0 space-y-8 lg:space-y-0 mb-16">
        <div className="lg:w-1/3 w-full">
          <address className="text-white not-italic space-y-1">
            <p className="font-medium flex items-center space-x-2">
              <ImHome3 />
              <span>openmoreonline.com</span>
            </p>
            <p className="leading-relaxed text-start">
              No. 85, Moe Thauk Pan Street, Su Paung Housing, Htu Par Yone (1)
              Ward, Tharkayta Township, Yangon.
            </p>

            <p className="flex items-center space-x-2">
              <AiTwotonePhone />
              <a href="tel:09421014055">(+95) 09 4210 140 55</a>
            </p>
            <p className="flex items-center space-x-2">
              <HiMail />
              <a
                href="mailto:"
                className="text-brown hover:text-brown-accent-secondary"
              >
                info@digitalmarketing.com.mm
              </a>
            </p>
          </address>
        </div>
        <div>
          <h2 className="text-white text-lg uppercase mb-5 text-start">
            Let's Keep in Touch
          </h2>
          <p className="text-medium text-start">
            အီးမေးလ် တစ်စောင်၊ ဖုန်းခေါ်ဆိုမှု တစ်ခု၊ ဘယ်နည်းလမ်းနဲ့ မဆို
            ကျွန်တော်တို့ကို ဆက်သွယ်ပြီး သိချင် လိုချင်သည်များကို
            စုံစမ်းနိုင်ပါတယ်။
          </p>
        </div>
      </div>
      <Map />
    </>
  );
};

export default ContactusPage;
