import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "./api/axios";

const initialState = {
  items: [],
  status: "idel",
  error: null,
};

// https://openmoreonline.com/api/v1/index/{latest or cate_id or sub_type_id or sub_sector_id}/{null/1,2,3/sublinksid}

// initial items
export const fetchItems = createAsyncThunk(
  "items/fetchItems",
  async ({ endpoint, id }) => {
    try {
      const res = await api.get(`/index/${endpoint}/${id}`);
      return res.data.data;
    } catch (err) {
      throw err.message;
    }
  }
);

const itemsSlice = createSlice({
  name: "items",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state, action) => {
        state.status = "pending";
        state.items = [];
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.status = "success";
        state.items = action.payload;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.status = "failed";
        if (action.error.message === "Request failed with status code 429")
          state.error =
            "You reached maximum page reload limit. Please wait a moment and reload.";
        else state.error = action.error.message;
      });
  },
});

export const selectItems = (state) => state.items.items;
export const selectItemsStatus = (state) => state.items.status;
export const selectItemsError = (state) => state.items.error;
export default itemsSlice.reducer;
