import React from "react";
import logo from "../assets/logo.jpg";

const Logo = () => {
  return (
    <div className="flex items-center space-x-3">
      <img src={logo} alt="open-more-online" className="w-10 h-10" />
      <span className="text-white tracking-widest font-thin text-medium">
        #OpenMoreOnline
      </span>
    </div>
  );
};

export default Logo;
