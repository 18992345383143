import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getMoreItems } from "../features/api/axios";

const useItems = (lastIndex) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const [hasNextItems, setHasNextItems] = useState(false);

  const location = useLocation();
  const { id } = useParams();

  const ALL_PATH = ["category", "sub-sectors", "sub-types"];

  // initial fetch items
  const [pathName] = location.pathname
    .split("/")
    .filter((cur) => ALL_PATH.includes(cur));
  const config = {
    path: pathName,
    id,
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError({});

    const controller = new AbortController();
    const { signal } = controller;
    const getMoreData = setTimeout(() => {
      getMoreItems(config, lastIndex, { signal })
        .then(({ data }) => {
          setResults((prev) => [...prev, ...data]);
          setHasNextItems(Boolean(data.length));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (signal.aborted) return;
          setIsError(true);
          setError({ message: err.message });
        });
    }, 1000);

    return () => {
      controller.abort();
      return clearTimeout(getMoreData);
    };
  }, [lastIndex]);

  return { results, isLoading, isError, error, hasNextItems };
};

export default useItems;
