import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCategory,
  selectError,
  selectStatus,
} from "../features/navLinksSlice";
import DropdownBusiness from "./DropdownBusiness";
import DropdownIndustry from "./DropdownIndustry";
import Loader from "./Loader";

const NavLinks = () => {
  const categories = useSelector(selectCategory);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  let content;
  if (status === "pending") {
    content = (
      <div className="py-7 hidden xl:block">
        <Loader variant={true} />
      </div>
    );
  } else if (status === "success") {
    content = (
      <motion.ul
        className="hidden xl:flex items-center space-x-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        layout
      >
        <li>
          <NavLink to="/" className="nav__link-main" end>
            Home
          </NavLink>
        </li>
        {categories.map((link) => {
          return (
            <li key={link.id}>
              <NavLink
                to={`category/${link.id}`}
                className="nav__link-main"
                end
              >
                {link.name}
              </NavLink>
            </li>
          );
        })}

        <DropdownBusiness />
        <DropdownIndustry />
      </motion.ul>
    );
  } else if (status === "failed") {
    content = <p className="py-7 hidden xl:flex">{error}</p>;
  }

  return <>{content}</>;
};

export default NavLinks;
