import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "./api/axios";

const requestOne = api.get(`/header/category`);
const requestTwo = api.get(`/header/sectors`);
const requestThree = api.get(`/header/types`);

const initialState = {
  category: [],
  status: "idle",
  error: null,
  menuOpen: false,
};

export const fetchLinks = createAsyncThunk("navlinks/fetchLinks", async () => {
  try {
    const res = await axios.all([requestOne, requestTwo, requestThree]);
    const [allLinks, [business], [industry]] = res.map((cur) => cur.data.data);
    const { data: businessData } = await api.get(`/sub-sectors/${business.id}`);
    const { data: industryData } = await api.get(`/sub-types/${industry.id}`);

    return [
      allLinks,
      { ...business, sublinks: businessData.data },
      { ...industry, sublinks: industryData.data },
    ];
  } catch (err) {
    throw err.message;
  }
});

const navlinksSlice = createSlice({
  name: "navlinks",
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      state.menuOpen = !state.menuOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinks.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchLinks.fulfilled, (state, { payload }) => {
        if (!payload) return;
        const [category, business, industry] = payload;
        state.category = category;
        state.business = business;
        state.industry = industry;
        state.status = "success";
      })
      .addCase(fetchLinks.rejected, (state, action) => {
        state.status = "failed";
        if (action.error.message === "Request failed with status code 429")
          state.error =
            "You reached maximum page reload limit. Please wait a moment and reload.";
        else state.error = action.error.message;
      });
  },
});

export const { toggleMenu } = navlinksSlice.actions;

export const selectCategory = (state) => state.navlinks.category;
export const selectBusiness = (state) => state.navlinks.business;
export const selectIndustry = (state) => state.navlinks.industry;
export const selectStatus = (state) => state.navlinks.status;
export const selectError = (state) => state.navlinks.error;
export const selectMenuOpen = (state) => state.navlinks.menuOpen;
export const selectBusinessSublinks = (state) =>
  state.navlinks.business.sublinks;
export const selectIndustrySublinks = (state) =>
  state.navlinks.industry.sublinks;

export default navlinksSlice.reducer;
