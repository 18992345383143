import React from "react";
import { useNavigate } from "react-router-dom";

const AboutusPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <h1 className="uppercase text-2xl mb-12">our story</h1>
      <p className="mb-16">
        OpenMoreOnlin.com သည် Hap Eye Co,.Ltd မှ Digital Marketing
        ဝန်ဆောင်မှုများ ပေးနေသော အဖွဲ့အစည်းဖြစ်သည်။ မြန်မာနိုင်ငံတွင်
        အင်တာနက်သုံးသူ တဟုန်ထိုး များပြားလာသည်နှင့်အညီ Online ပေါ်သို့ မဖြစ်မနေ
        လှမ်းတက်ရန် လိုအပ်လာသော စီးပွားရေးလုပ်ငန်းတိုင်းအတွက် ကူညီပေးလိုခြင်း
        ဖြစ်ပါသည်။ သင့်လုပ်ငန်းအတွက် ပြည်တွင်း၌သာမက ပြည်ပဈေးကွက်အထိ
        ရင်ဘောင်တန်းနိုင်မည့် မာကတ်တင်း နည်းဗျူဟာများနှင့်
        အကောင်အထည်ဖော်ဆောင်ရွက်ရန် အတွေ့အကြုံရင့် ပညာရှင်များဖြင့်
        ဖွဲ့စည်းထားသောကြောင့် ယုံကြည်စိတ်ချစွာ လက်တွဲနိုင်ပါသည်။
      </p>
      <button
        className="uppercase text-white px-8 py-3 bg-brown rounded-full hover:bg-brown-accent duration-200 self-center mb-5"
        onClick={() => {
          navigate("/contact");
        }}
      >
        Contact us
      </button>
    </div>
  );
};

export default AboutusPage;
