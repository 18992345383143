import React from "react";
import { motion } from "framer-motion";
import { child } from "./motion";
const NavLinkWrapper = ({ children, variant }) => {
  let content;
  if (variant) {
    content = (
      <motion.li
        className="relative group nav__link-mobileContainer group"
        variants={child}
      >
        {children}
      </motion.li>
    );
  } else {
    content = <li className="relative group py-7">{children}</li>;
  }
  return content;
};

export default NavLinkWrapper;
