import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import Masonry from "react-masonry-css";
import { useDispatch, useSelector } from "react-redux";
import { selectItems } from "../features/itemsSlice";
import { addLightBoxItems, fetchYoutubeVideo } from "../features/lightboxSlice";
import useItems from "../hooks/useItems";
import Loader from "./Loader";
import YouTubeOverlay from "./YouTubeOverlay";

const Gallery = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const [moreItems, setMoreItems] = useState([]);
  const [lastIndex, setlastIndex] = useState(items.at(-1).id);
  const { results, isLoading, isError, error, hasNextItems } =
    useItems(lastIndex);

  useEffect(() => {
    setMoreItems([...items, ...results]);
  }, [results, items]);

  // observer
  const intObserver = useRef();
  const lastItemsRef = useCallback(
    (item) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((items) => {
        if (items[0]?.isIntersecting && hasNextItems) {
          setlastIndex(results.at(-1)?.id);
        }
      });

      if (item) intObserver.current.observe(item);
    },
    [isLoading, results, hasNextItems]
  );

  // error
  if (isError) return <p className="text-center">{error.message}</p>;

  // content
  const content = moreItems.map((cur, i) => {
    if (moreItems.length === i + 1) {
      return (
        <div className="relative" key={cur.id}>
          <motion.img
            height={500}
            width={333}
            loading="lazy"
            src={cur.value}
            className={`w-full object-cover cursor-pointer ${
              cur.content_type === "youtube_link" ? "youtube__image" : ""
            }`}
            alt={cur.value}
            onClick={toggleLightbox.bind(null, { index: i, clickedImg: cur })}
            ref={lastItemsRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            layout
          />

          {cur.content_type === "youtube_link" && (
            <YouTubeOverlay
              toggle={toggleLightbox.bind(null, { index: i, clickedImg: cur })}
            />
          )}
        </div>
      );
    }

    return (
      <div className="relative" key={cur.id}>
        <motion.img
          height={500}
          width={333}
          loading="lazy"
          src={cur.value}
          className={`w-full object-cover cursor-pointer ${
            cur.content_type === "youtube_link" ? "youtube__image" : ""
          }`}
          alt={cur.value}
          onClick={toggleLightbox.bind(null, { index: i, clickedImg: cur })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          layout
        />
        {cur.content_type === "youtube_link" && (
          <YouTubeOverlay
            toggle={toggleLightbox.bind(null, { index: i, clickedImg: cur })}
          />
        )}
      </div>
    );
  });

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  // lightbox toggle
  async function toggleLightbox({ index, clickedImg }) {
    if (clickedImg.content_type === "youtube_link") {
      dispatch(fetchYoutubeVideo(clickedImg.id));
      return;
    }
    dispatch(addLightBoxItems({ items: [...moreItems], clickedId: index }));
  }
  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid text-center"
        columnClassName="my-masonry-grid_column relative"
      >
        {content}
      </Masonry>
      {isLoading && <Loader styles="my-8" />}
    </>
  );
};

export default Gallery;
