import { useState, useEffect } from "react";
import { getMoreSublinks } from "../features/api/axios";

const useSublinks = (linkName, linkId, lastIndex) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const [hasNextSublinks, setHasNextSublinks] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError({});

    const controller = new AbortController();
    const { signal } = controller;
    getMoreSublinks(linkName, linkId, lastIndex, { signal })
      .then(({ data }) => {
        setResults((prev) => [...prev, ...data]);
        setHasNextSublinks(Boolean(data.length));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (signal.aborted) return;
        setIsError(true);
        setError({ message: err.message });
      });

    return () => controller.abort();
  }, [lastIndex]);

  return { results, isLoading, isError, error, hasNextSublinks };
};

export default useSublinks;
