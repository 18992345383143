import React from "react";
import { useState } from "react";
import MobileMenu from "./MobileMenu";
import NavLinks from "./NavLinks";
import { HiDotsVertical } from "react-icons/hi";
import Info from "./Info";
import Logo from "../UI/Logo";

const NavBar = () => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  return (
    <>
      <header className="bg-black fixed w-full top-0 z-40">
        <nav className="flex items-center py-3 xl:py-0 px-4 md:px-12 ">
          <Logo />

          <div className="ml-auto flex items-center space-x-8">
            <NavLinks />

            <button
              id="menu-btn"
              type="button"
              aria-label="mobile_menu-btn"
              className={`${
                isMobileMenuOpen && "open"
              } z-40 block hamburger xl:hidden focus:outline-none`}
              onClick={handleMenu}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>

            <HiDotsVertical
              className="text-xl cursor-pointer duration-100 text-gray hover:text-white hidden xl:block"
              onClick={handleInfo}
            />
          </div>
        </nav>
        <Info isInfoOpen={isInfoOpen} handleInfo={handleInfo} />
        <MobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          handleMenu={handleMenu}
        />
      </header>
      <div className="h-52 xl:h-60 hero__bg mt-16"></div>
    </>
  );
};

export default NavBar;
