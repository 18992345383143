import React from "react";
import PageNotFound from "./PageNotFound";
import HomePage from "./HomePage";
import AboutusPage from "./AboutusPage";
import ContactusPage from "./ContactusPage";
import Subsectors from "./Subsectors";
import Subtypes from "./Subtypes";
import { Routes, Route } from "react-router-dom";
import Structure from "../UI/Structure";
import CategoryPage from "./CategoryPage";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Structure />}>
        <Route index element={<HomePage />} />
        <Route path="/about" element={<AboutusPage />} />
        <Route path="/contact" element={<ContactusPage />} />
        <Route path="/category/:id" element={<CategoryPage />} />
        <Route path="/sub-sectors/:id" element={<Subsectors />} />
        <Route path="/sub-types/:id" element={<Subtypes />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Pages;
