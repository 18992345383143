import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "./api/axios";

const initialState = {
  lightboxItems: [],
  isOpened: false,
  status: "idle",
  type: "",
  clickedId: 0,
};

export const fetchYoutubeVideo = createAsyncThunk(
  "lightbox/fetchYoutubeVideo",
  async (id) => {
    const { data } = await api.get(`/show/${id}`);
    const content = data.data;
    return content;
  }
);

const lightboxSlice = createSlice({
  name: "lightbox",
  initialState,
  reducers: {
    addLightBoxItems: (state, { payload }) => {
      state.status = "idel";
      state.type = "image";
      state.lightboxItems = [...payload.items];
      state.clickedId = payload.clickedId;
      state.isOpened = !state.isOpened;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYoutubeVideo.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchYoutubeVideo.fulfilled, (state, { payload }) => {
        const prepareData = [
          {
            id: payload.id,
            value: `https:${payload.value}`,
            content_type: "youtube_link",
          },
        ];
        state.lightboxItems = prepareData;
        state.type = "youtube";
        state.status = "success";
        state.clickedId = 0;
        state.isOpened = !state.isOpened;
      })
      .addCase(fetchYoutubeVideo.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addLightBoxItems, lightboxToggler } = lightboxSlice.actions;
export const selectLightboxType = (state) => state.lightbox.type;
export const selectLightboxItems = (state) => state.lightbox.lightboxItems;
export const selectIsOpenedLightbox = (state) => state.lightbox.isOpened;
export const selectClickedId = (state) => state.lightbox.clickedId;
export default lightboxSlice.reducer;
