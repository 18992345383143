import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";

const Structure = () => {
  return (
    <>
      <div className="bg-dark-brown min-h-screen overflow-x-hidden flex flex-col">
        <NavBar />

        <main>
          <section className="mx-6 md:mx-10 -translate-y-20 xl:-translate-y-12">
            <motion.div
              className="p-4 rounded-lg bg-black text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              layout
            >
              {<Outlet />}
            </motion.div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Structure;
