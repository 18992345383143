import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black p-6 md:px-12 mt-auto">
      <span className="text-white font-medium uppercase text-xs selection:text-white tracking-widest">
        © openmoreonline.com
      </span>
    </footer>
  );
};

export default Footer;
